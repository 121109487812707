import { CreativeSpec } from "@core/models/creative.types";

import { LayerTaint } from "./creatives-edit-master.service";
import { iterateSpecificLayers } from "./layout-helpers";

export function applyFold<T>(object: T, list: string[]) {
  function _fold(object: T, list: string[]) {
    const copyList = JSON.parse(JSON.stringify(list));

    if (copyList.length === 0) {
      return object;
    }

    const key = copyList.shift();

    if (object[key] == null) {
      return null;
    }

    if (copyList.length > 0) {
      return _fold(object[key], copyList);
    }

    return object[key];
  }
  return _fold(object, list);
}

export function taintLayers(
  spec: CreativeSpec,
  defaultSpec: CreativeSpec,
  whitelist: string[][],
  taintedLayers: Record<string, LayerTaint>,
) {
  const specCopy: CreativeSpec = JSON.parse(JSON.stringify(spec));
  const defaultCopy: CreativeSpec = JSON.parse(JSON.stringify(defaultSpec));

  const specLayers = Array.from(
    iterateSpecificLayers(specCopy.sections[0].layers, true),
  );
  const defaultLayers = Array.from(
    iterateSpecificLayers(defaultCopy.sections[0].layers, true),
  );
  specLayers.forEach((layer) => {
    const defaultLayer = defaultLayers.find(
      (bl) => bl.identifier === layer.identifier,
    );

    if (defaultLayer && layer.type != "group") {
      whitelist.forEach((wlItem) => {
        const wlCopy = JSON.parse(JSON.stringify(wlItem));
        const lastItem = wlCopy.pop();
        const layerProperty = applyFold(layer, wlCopy);
        const defaultProperty = applyFold(defaultLayer, wlCopy);
        if (taintedLayers[layer.identifier] == null) {
          taintedLayers[layer.identifier] = {};
        }

        if (lastItem === "opacity" || lastItem === "position") {
          taintedLayers[layer.identifier][lastItem] =
            taintedLayers[layer.identifier][lastItem] ||
            !(
              JSON.stringify(layerProperty[lastItem]) ===
              JSON.stringify(defaultProperty[lastItem])
            );
        } else {
          if (taintedLayers[layer.identifier].properties == null) {
            taintedLayers[layer.identifier].properties = {};
          }
          taintedLayers[layer.identifier].properties[lastItem] =
            taintedLayers[layer.identifier].properties[lastItem] ||
            !(
              JSON.stringify(layerProperty[lastItem]) ===
              JSON.stringify(defaultProperty[lastItem])
            );
        }
      });
    }
  });
}

export function copySpec(
  spec: CreativeSpec,
  overwrite: CreativeSpec,
  whitelist: string[][],
  taintedLayers: Record<string, LayerTaint>,
) {
  const backup: CreativeSpec = JSON.parse(JSON.stringify(spec));
  const overwriteCopy: CreativeSpec = JSON.parse(JSON.stringify(overwrite));

  const overwriteLayers = Array.from(
    iterateSpecificLayers(overwriteCopy.sections[0].layers, true),
  );
  const layersBackup = Array.from(
    iterateSpecificLayers(backup.sections[0].layers, true),
  );

  overwriteLayers.forEach((layer) => {
    const backupLayer = layersBackup.find(
      (bl) => bl.identifier === layer.identifier,
    );

    if (backupLayer && layer.type != "group") {
      whitelist.forEach((wlItem) => {
        const wlCopy = JSON.parse(JSON.stringify(wlItem));
        const lastItem = wlCopy.pop();
        const layerProperty = applyFold(layer, wlCopy);
        const backupLayerProperty = applyFold(backupLayer, wlCopy);

        if (layerProperty == null) {
          return;
        }

        const checkTaint =
          taintedLayers[layer.identifier] != null &&
          taintedLayers[layer.identifier][lastItem] === true;
        const checkTaintProperties =
          taintedLayers[layer.identifier] != null &&
          taintedLayers[layer.identifier].properties != null &&
          taintedLayers[layer.identifier].properties[lastItem] === true;

        if (!checkTaint && !checkTaintProperties) {
          return;
        }

        if (backupLayerProperty[lastItem] == null) {
          delete layerProperty[lastItem];
          return;
        }

        layerProperty[lastItem] = backupLayerProperty[lastItem];
      });
    }
  });

  return overwriteCopy;
}
