import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import {
  CreativeCellType,
  CreativeEditorMode,
  CreativeSpecItem,
} from "@core/models/creative.types";
import { DesignFormat } from "@core/services/creatives/creatives-edit-master.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";

import { TabInfo } from "@theme/@confect/wrappers/tab-menu/tab-menu.component";

import { CreativeError } from "../../types/creative-error";

export interface SpecGroup {
  items: CreativeSpecItem<any>[];
  group_name?: string;
  group_id?: string;
  group_name_short?: string;
  group_icon?: string;
}

@Component({
  selector: "ngx-spec-group-handler",
  templateUrl: "./spec-group-handler.component.html",
  standalone: false,
})
export class SpecGroupHandlerComponent implements OnInit {
  videoGroups = new Set(["Effect in", "Effect out", "Effect middle"]);

  _specGroups: SpecGroup[] = [];
  tabs: TabInfo[] = [];

  @Input() slimMode = false;

  private _rawSpecGroups: SpecGroup[] = [];
  @Input() set specGroups(to: any) {
    this._rawSpecGroups = to;
    this.calculateSpecGroups();
  }

  @Input() editor: CreativesEditService;

  _raisedError: CreativeError = null;

  showErrorIndexes = {};

  @Input()
  set raisedError(to) {
    this._raisedError = to;
    this.refreshShowError();
  }

  get raisedError() {
    return this._raisedError;
  }

  // The currently configured settings of the spec
  @Input() specSettings: any = {};

  @Input() helpEnabled = true;

  @Input() extraSettings: any = {};

  @Input() mainFormat: boolean = false;

  @Input() format: DesignFormat | null = null;

  _editorMode: CreativeEditorMode = CreativeEditorMode.IMAGE;
  @Input() set editorMode(to: CreativeEditorMode) {
    this._editorMode = to;
    this.calculateSpecGroups();
  }

  _specLimiter: Record<string, Set<string>> = {};

  @Input() set specLimiter(to: Record<string, string[]>) {
    if (!to) {
      this._specLimiter = {};
      return;
    }

    this._specLimiter = {};

    for (const [key, obj] of Object.entries(to)) {
      this._specLimiter[key] = new Set(obj);
    }
    this.calculateSpecGroups();
  }

  @Output() syncLayer = new EventEmitter<{ layer: string; key: string }>();

  constructor() {}

  ngOnInit() {}

  calculateSpecGroups() {
    if (!this._rawSpecGroups) {
      this._specGroups = [];
    }

    const hasLimiter = Object.keys(this._specLimiter).length > 0;
    this._specGroups = this._rawSpecGroups.filter((sg) => {
      if (this.videoGroups.has(sg.group_name)) {
        return false;
      }

      // Only show included groups
      if (hasLimiter) {
        // If group not in limiter, exclude completely
        if (!this._specLimiter[sg.group_name]) {
          return false;
        }
      }

      return true;
    });

    const hasMultipleProducts = this.editor
      ? Array.from(this.editor.iterateLayers(false)).filter(
          (l) => l.config?.grid_config[0].type === CreativeCellType.PRODUCT,
        ).length > 1
      : false;

    this.tabs = this._specGroups
      .map((specGroup) => {
        return {
          name: specGroup.group_name_short,
          icon: specGroup.group_icon,
          id: specGroup.group_id,
        };
      })
      .filter((t) => {
        if (t.id === "reference") {
          return hasMultipleProducts;
        }
        return true;
      });
    this.tabs.push({ name: "Layer", icon: "pen", id: "layer" });
  }

  refreshShowError() {
    this.showErrorIndexes = {};

    if (!this.raisedError) {
      return;
    }

    if (!this.raisedError.error_key) {
      return;
    }

    const k = this.raisedError.error_key;

    this._specGroups.forEach((sg, i) => {
      sg.items.forEach((item) => {
        const vt = item.value_type;
        if (k === "PRODUCT_REFERENCE" && vt === "product_subscription") {
          this.showErrorIndexes[i] = true;
        } else if (
          k === "EFFECT_DURATION" &&
          (vt === "EffectTransition" || vt === "EffectAction")
        ) {
          this.showErrorIndexes[i] = true;
        }
      });
    });
  }
  minorSpecItem(name) {
    let icon = "";
    switch (name) {
      case "Left":
        icon = "arrow_long_right";
        break;
      case "Top":
        icon = "arrow_long_down";
        break;
      case "Width":
        icon = "arrow_doublesided_horizontal";
        break;
      case "Height":
        icon = "arrow_doublesided_vertical";
        break;
    }
    return {
      default: { x: 0, y: 0, width: 100, height: 100 },
      help: { image: null, text: "Control position and size of object." },
      name: "media_position",
      options: null,
      value_type: "position",
      display: name,
      icon: icon,
    };
  }

  setPosition(key: string, value) {
    const newVal = parseFloat(value);
    const position: {
      x: number;
      y: number;
      width: number;
      height: number;
    } = JSON.parse(JSON.stringify(this.editor.layer.position));

    position[key] = isNaN(newVal) ? 0 : newVal;
    this.editor.updateLayerPosition(this.editor.layer.identifier, position);
  }

  setOpacity(key: string, value) {
    const newVal = JSON.parse(JSON.stringify(parseFloat(value)));
    const opactiy =
      JSON.parse(JSON.stringify(this.editor.layer.LAYER.opacity)) != null
        ? newVal
        : 100;

    this.editor.setOpacity(key, opactiy);
  }
}
